<script setup lang="ts">
import UIconMenu from './icons/UIconMenu.vue';
import UIconClose from './icons/UIconClose.vue';

withDefaults(
  defineProps<{
    active: boolean;
  }>(),
  {
    active: false,
  }
);
</script>

<template>
  <button class="u-menu-button" aria-label="Toggle navigation">
    <UIconMenu v-show="!active" />
    <UIconClose v-show="active" />
  </button>
</template>

<style scoped lang="scss">
@layer components {
  .u-menu-button {
    border: unset;
    background: unset;
    cursor: pointer;

    & > svg {
      font-size: 2rem;
    }
  }
}
</style>
