<script setup lang="ts">
withDefaults(
  defineProps<{
    url: string;
    label: string;
    active?: boolean;
  }>(),
  {
    active: false,
  }
);
</script>

<template>
  <a :class="['u-navigation-link', { 'u-navigation-link--active': active }]" :href="url">{{ label }}</a>
</template>

<style scoped lang="scss">
@layer components {
  .u-navigation-link {
    position: relative;
    left: 0;
    display: inline-block;
    text-decoration: none;
    font-style: normal;
    font-weight: 400;

    &::after {
      content: '';
      position: absolute;
      left: 0;
      bottom: -0.1em;
      border-bottom: 0.08em solid currentColor;
      width: 0;
    }

    &:not(.u-navigation-link--active):hover::after {
      transition: width 0.5s;
      width: 100%;
    }

    &.u-navigation-link--active::after {
      width: 100%;
    }
  }
}
</style>
