<script setup lang="ts"></script>

<template>
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      id="Close"
      d="M4 4 L20 20 M4 20L20 4"
      stroke="black"
      stroke-width="2"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
</template>

<style scoped lang="scss">
svg {
  path {
    stroke: currentColor;
  }
}
</style>
